<div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">Kit Modifications</h5>
          <p>
            The swatch kits can be modified in the following ways to fit the needs of your textiles course.
          </p>
          <ul>
            <li><b>Removing the Master List</b></li>
            <ul>
              <li>Kits can be shipped without the master list, encouraging students to identify fabrics independently as they learn textile concepts. The master list can be provided separately to the instructor for later distribution.</li>
            </ul>
            <li><b>Pre-Filled Mounting Sheets</b></li>
            <ul>
              <li>For courses with limited time for in-depth textile study, pre-filled mounting sheets are available. These replace blank sheets, providing students with comprehensive swatch information upfront.</li>
            </ul>
          </ul>
          <p>
            For more details on customization options and pricing, please contact <a href="mailto:susan.elliott@textilefabric.com">Susan Elliott</a>.
          </p>
        </div>
      </div>
    </div>
  </div>