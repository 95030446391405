<div class="row">
    <div class="col-sm-12">
        <div class="card">
            <div class="card-body">
            <div class="row">
                <div class="col-sm-12">
                    <h5 class="card-title">Complimentary Instructor Materials</h5>
                </div>
                
                <div class="col-sm-12 col-md-8">
                    <ul>
                        <li>Complimentary preview kits are available upon request</li>
                        <li>Upon adoption of any of our swatch kits, instructors receive the following materials:</li>
                        <ul>
                        <li><b>Fabric Swatch Kit</b></li>
                        <li><b>Fiber Samples Packet</b></li>
                        <li><b>Instructor Manual</b> (digital and hard copy formats)</li>
                        <li><b>Powerpoint Presentation</b> (a comprehensive fiber-to-finish discussion of textiles, featuring references to swatches)</li>
                        <li><b>Mounted Swatch Images</b></li>
                        <li><b>Completed Mounting Sheets</b></li>
                        <li><b>Textile Testing Lab Manual</b></li>
                        <li><b>Questions and Answers</b> (available with Basic, Interior Design and Consumer Swatch Kits)</li>
                        <li><b>Link to a Playlist</b> of various textile videos</li>
                        </ul>
                    </ul>
                </div>
                <div class="col-sm-12 col-md-3 text-center">
                    <img src="/assets/images/textile-def.png" width="300px" />
                </div>
            </div>
            </div>
        </div>
    </div>
</div>