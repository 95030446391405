import { CelebrationComponent } from './pages/celebration/celebration.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './pages/home/home.component';
import { TermsComponent } from './pages/terms/terms.component';
import { ResourcesComponent } from './pages/resources/resources.component';
import { AboutComponent } from './pages/about/about.component';
import { ContactComponent } from './pages/contact/contact.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';
import { ProductsComponent } from './pages/products/products.component';
import { CategoriesComponent } from './pages/categories/categories.component';
import { SubcategoriesComponent } from './pages/categories/subcategories/subcategories.component';
import { ShoppingCartComponent } from './pages/shopping-cart/shopping-cart.component';
import { LoginComponent } from './pages/login/login.component';
import { RegistrationComponent } from './pages/registration/registration.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { StripeChargeComponent } from './pages/stripe-charge/stripe-charge.component';
import { ConfirmationComponent } from './pages/confirmation/confirmation.component';
import { ShippingComponent } from './pages/shipping/shipping.component';
import { BillingComponent } from './pages/billing/billing.component';
import { DashboardComponent } from './pages/admin/dashboard/dashboard.component';
import { OrdersComponent } from './pages/admin/orders/orders.component';
import { OrderDetailComponent } from './pages/admin/orders/order-detail/order-detail.component';
import { UsersComponent } from './pages/admin/users/users.component';
import { UserDetailComponent } from './pages/admin/users/user-detail/user-detail.component';
import { AdminUsersResolver } from './resolvers/admin-users-resolver.service';
import { ResetPasswordComponent } from './pages/resetpassword/resetpassword.component';
import { AdminProductsComponent } from './pages/admin/products/products.component';
import { ProductDetailComponent } from './pages/admin/products/product-detail/product-detail.component';
import { AdminCategoriesComponent } from './pages/admin/categories/admin-categories.component';
import { AdminCategoryDetailComponent } from './pages/admin/categories/admin-category-detail/admin-category-detail.component';
import { DetailsComponent } from './pages/products/details/details.component';
import { SchoolComponent } from './pages/school/school.component';
import { StudentResourcesComponent } from './pages/student-resources/student-resources.component';
import { MediaComponent } from './pages/media/media.component';
import { AdminMediaComponent } from './pages/admin/media/admin-media.component';
import { AdminMediaDetailComponent } from './pages/admin/media/admin-media-detail/admin-media-detail.component';
import { AuthGuard } from './auth/auth-guard';
import { ReturnsComponent } from './pages/returns/returns.component';
import { CheckresetpasswordComponent } from './pages/checkresetpassword/checkresetpassword.component';
import { UserOrdersComponent } from './pages/orders/user-orders.component';
import { TestComponent } from './pages/products/test/test.component';

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  { path: 'categories', component: CategoriesComponent },
  { path: 'categories/:id', component: SubcategoriesComponent },
  { path: 'checkresetpassword/:token', component: CheckresetpasswordComponent },
  { path: 'products/:id', component: ProductsComponent },
  { path: 'test', component: TestComponent },
  { path: 'details/:id', component: DetailsComponent },
  { path: 'privacy', component: PrivacyComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'about', component: AboutComponent },
  { path: 'returns', component: ReturnsComponent },
  { path: 'login', component: LoginComponent },
  { path: 'celebration', component: CelebrationComponent },
  { path: 'orders',
    children: [
      { path: 'billTo', component: BillingComponent},
      { path: 'shipTo', component: ShippingComponent },
      { path: 'confirmation', component: ConfirmationComponent },
      { path: 'payment', component: StripeChargeComponent }
    ]
  },
  { path: 'registration', component: RegistrationComponent },
  { path: 'resetpassword', component: ResetPasswordComponent },
  { path: 'resources', component: ResourcesComponent },
  { path: 'student-resources', component: StudentResourcesComponent },
  { path: 'school', component: SchoolComponent },
  { path: 'shopping-cart', component: ShoppingCartComponent },
  { path: 'terms', component: TermsComponent },
  { path: 'users',
    children: [
      { path: 'profile', component: ProfileComponent },
      { path: 'orders', component: UserOrdersComponent }
    ], canActivate: [AuthGuard]
  },
  { path: 'videos', component: MediaComponent },
  { path: 'admin',
      children: [
        { path: '', component: DashboardComponent },
        { path: 'orders', component: OrdersComponent },
        { path: 'details/:id', component: OrderDetailComponent },
        { path: 'users', component: UsersComponent, resolve: { users: AdminUsersResolver } },
        { path: 'users/:id', component: UserDetailComponent },
        { path: 'products', component: AdminProductsComponent },
        { path: 'products/:id', component: ProductDetailComponent },
        { path: 'categories', component: AdminCategoriesComponent },
        { path: 'categories/:id', component: AdminCategoryDetailComponent },
        { path: 'media', component: AdminMediaComponent },
        { path: 'media/:id', component: AdminMediaDetailComponent}
      ], canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AdminUsersResolver]
})
export class AppRoutingModule { }
