import { Component } from '@angular/core';

@Component({
  selector: 'app-orders',
  templateUrl: './user-orders.component.html',
  styleUrl: './user-orders.component.scss'
})
export class UserOrdersComponent {

}
