import { Component } from '@angular/core';

@Component({
  selector: 'app-customized-kits',
  standalone: true,
  imports: [],
  templateUrl: './customized-kits.component.html',
  styleUrl: './customized-kits.component.scss'
})
export class CustomizedKitsComponent {

}
