<div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">Swatch Kit Add-Ons</h5>
          <div class="row">

              <div class="col-sm-12 col-md-8">
                <p>
                  Customize your kit by adding one or more supplemental items:
                </p>
                <ul>
                  <li><b>Fiber Samples Packet</b></li>
                  <li><b>Yarn Samples Packet</b></li>
                  <li><b>Fiber to Yarn Activity Packet</b></li>
                  <li><b>Trim Kit</b></li>
                  <li><b>LED Stitch Counter</b></li>
                  <li><b>Textilepedia</b></li>
                  <li><b>Fashion Designer's Textile Directory</b></li>
                </ul>
              </div>
              <div class="col-sm-12 col-md-3 text-center">
                <img src="/assets/images/instructor_resources/swatch_kit_addon.jpg" width="300px" />
              </div>

          </div>
        </div>
      </div>
    </div>
  </div>