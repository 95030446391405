import { Component } from '@angular/core';

@Component({
  selector: 'app-kit-modifications',
  standalone: true,
  imports: [],
  templateUrl: './kit-modifications.component.html',
  styleUrl: './kit-modifications.component.scss'
})
export class KitModificationsComponent {

}
