import { Component } from '@angular/core';

@Component({
  selector: 'app-swatch-kit-addons',
  standalone: true,
  imports: [],
  templateUrl: './swatch-kit-addons.component.html',
  styleUrl: './swatch-kit-addons.component.scss'
})
export class SwatchKitAddonsComponent {

}
