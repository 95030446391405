<div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">Hybrid Swatch Kit Bundles for Textile Courses</h5>
          <div class="row">

              <div class="col-sm-12 col-md-8">
                <p>
                  Teaching textiles effectively requires thorough preparation, especially when catering to both fashion and interior design majors.  TFC’s hybrid swatch kit bundles provide tailored learning opportunities, ensuring that students gain hands-on experience with fabrics relevant to their respective fields.
                </p>
                <p>
                  For bundling options and pricing, please contact <a href="mailto:susan.elliott@textilefabric.com">Susan Elliott</a>.
                </p>
              </div>
              <div class="col-sm-12 col-md-3 text-center">
                <img src="/assets/images/instructor_resources/hybrid_swatch_kit.jpeg" width="300px" />
              </div>

          </div>
        </div>
      </div>
    </div>
  </div>