import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { UserProfile } from './../../models/user-profile.model';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  profileForm: FormGroup;
  submitted = false;
  profile: UserProfile;

  constructor(
    private formBuilder:FormBuilder
  ) { }

  ngOnInit(): void {
    this.profileForm = this.createProfileForm();
  }

  get f() { return this.profileForm.controls; }

  createProfileForm() {
    return this.formBuilder.group({
      companyName: ['', Validators.required],
      firstName: [''],
      lastName: ['', Validators.required],
      emailAddress: ['', Validators.required],
      // billTo: this.formBuilder.group({
      //   address1:  ['', Validators.required],
      //   address2: [''],
      //   city: ['', Validators.required],
      //   stateCode: ['', Validators.required],
      //   postalCode: ['', Validators.required],
      //   countryCode: ['', Validators.required]
      // }),
      shipTo: this.formBuilder.group({
        address1:  ['', Validators.required],
        address2: [''],
        city: ['', Validators.required],
        stateCode: ['', Validators.required],
        postalCode: ['', Validators.required],
        countryCode: ['', Validators.required]
      })
    });
  }

  getFormValues() {
    return new UserProfile({
      companyName: this.f.companyName.value,
      firstName: this.f.firstName.value,
      lastName: this.f.lastName.value,
      emailAddress: this.f.emailAddress.value,
      billTo: {
        address1: this.profileForm.get('billTo.address1').value,
        address2: this.profileForm.get('billTo.address2').value,
        city: this.profileForm.get('billTo.city').value,
        stateCode: this.profileForm.get('billTo.stateCode').value,
        postalCode: this.profileForm.get('billTo.postalCode').value,
        countryCode: this.profileForm.get('billTo.countryCode').value,
        phoneNumber: this.profileForm.get('billTo.phoneNumber').value
      },
      shipTo: {
        address1: this.profileForm.get('shipTo.address1').value,
        address2: this.profileForm.get('shipTo.address2').value,
        city: this.profileForm.get('shipTo.city').value,
        stateCode: this.profileForm.get('shipTo.stateCode').value,
        postalCode: this.profileForm.get('shipTo.postalCode').value,
        countryCode: this.profileForm.get('shipTo.countryCode').value,
        phoneNumber: this.profileForm.get('shipTo.phoneNumber').value
      }
    });
  }

  submit() {
    this.submitted = true;

    if (this.profileForm.invalid) {
      return;
    }

    this.profile = this.getFormValues();

    // TODO: create service for storing profile

  }

}
