import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DataViewModule } from 'primeng/dataview';

import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
//import { DataTablesModule } from 'angular-datatables';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { ModalModule, BsModalRef } from 'ngx-bootstrap/modal';
import { SlugifyPipe } from './shared/pipes/slugify.pipe';
import { MarkdownModule } from 'ngx-markdown';
import { JwtModule } from '@auth0/angular-jwt';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatTabsModule } from '@angular/material/tabs';
import { SpecialCharacterDirective } from './shared/directives/specialCharacter.directive';
import { JwtInterceptor } from './_helpers/jwt.interceptor';
import { AuthGuard } from './auth/auth-guard';
import { TableModule } from 'primeng/table';
import { PaginatorModule } from 'primeng/paginator';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { NgxSpinnerModule } from "ngx-spinner";

import { SafePipe } from './shared/pipes/safe.pipe';

import { AdminService } from './services/site/admin.service';
import { CategoryService } from './services/site/category.service';
import { ErrorService } from './services/utility/error.service';
import { LoggerService } from './services/utility/logger.service';
import { NotificationService } from './services/site/notification.service';
import { SessionOrderService } from './services/site/session-order.service';
import { ShoppingCartService } from './services/site/shopping-cart.service';
import { TokenService } from './services/auth/token.service';
import { UserService } from './services/site/user.service';

import { AppComponent } from './app.component';
import { NavComponent } from './shared/nav/nav.component';
import { FooterComponent } from './shared/footer/footer.component';
import { HeaderComponent } from './shared/header/header.component';
import { HomeComponent } from './pages/home/home.component';
import { CategoriesComponent } from './pages/categories/categories.component';
import { ProductsComponent } from './pages/products/products.component';
import { ResourcesComponent } from './pages/resources/resources.component';
import { ContactComponent } from './pages/contact/contact.component';
import { TermsComponent } from './pages/terms/terms.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';
import { AboutComponent } from './pages/about/about.component';
import { MountedSwatchesComponent } from './pages/mounted-swatches/mounted-swatches.component';
import { LoginComponent } from './pages/login/login.component';
import { ShoppingCartComponent } from './pages/shopping-cart/shopping-cart.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { UserOrdersComponent } from './pages/orders/user-orders.component';
import { RegistrationComponent } from './pages/registration/registration.component';
import { BillingComponent } from './pages/billing/billing.component';
import { ShippingComponent } from './pages/shipping/shipping.component';
import { ConfirmationComponent } from './pages/confirmation/confirmation.component';
import { ProductComponent } from './pages/products/product/product.component';
import { StripePaymentComponent } from './pages/stripe-payment/stripe-payment.component';
import { StripeChargeComponent } from './pages/stripe-charge/stripe-charge.component';
import { DashboardComponent } from './pages/admin/dashboard/dashboard.component';
import { OrdersComponent } from './pages/admin/orders/orders.component';
import { OrderDetailComponent } from './pages/admin/orders/order-detail/order-detail.component';
import { UsersComponent } from './pages/admin/users/users.component';
import { UserDetailComponent } from './pages/admin/users/user-detail/user-detail.component';
import { ResetPasswordComponent } from './pages/resetpassword/resetpassword.component';
import { CheckresetpasswordComponent } from './pages/checkresetpassword/checkresetpassword.component';
import { AdminProductsComponent } from './pages/admin/products/products.component';
import { ProductDetailComponent } from './pages/admin/products/product-detail/product-detail.component';
import { AdminCategoriesComponent } from './pages/admin/categories/admin-categories.component';
import { AdminCategoryDetailComponent } from './pages/admin/categories/admin-category-detail/admin-category-detail.component';
import { LoadingComponent } from './shared/loading/loading.component';
import { CelebrationComponent } from './pages/celebration/celebration.component';
import { SubcategoriesComponent } from './pages/categories/subcategories/subcategories.component';
import { DetailsComponent } from './pages/products/details/details.component';
import { SchoolComponent } from './pages/school/school.component';
import { StudentResourcesComponent } from './pages/student-resources/student-resources.component';
import { MediaComponent } from './pages/media/media.component';
import { ReturnsComponent } from './pages/returns/returns.component';
import { AdminMediaComponent } from './pages/admin/media/admin-media.component';
import { AdminMediaDetailComponent } from './pages/admin/media/admin-media-detail/admin-media-detail.component';
import { ComplimentaryMaterialsComponent } from "./pages/resources/complimentary-materials/complimentary-materials.component";
import { CustomizedKitsComponent } from "./pages/resources/customized-kits/customized-kits.component";
import { HybridSwatchKitsComponent } from "./pages/resources/hybrid-swatch-kits/hybrid-swatch-kits.component";
import { SwatchKitAddonsComponent } from "./pages/resources/swatch-kit-addons/swatch-kit-addons.component";
import { KitModificationsComponent } from "./pages/resources/kit-modifications/kit-modifications.component";

export function tokenGetter() {
  return sessionStorage.getItem("jwt");
}

@NgModule({
  declarations: [
    AppComponent,
    NavComponent,
    FooterComponent,
    HeaderComponent,
    HomeComponent,
    CategoriesComponent,
    ProductsComponent,
    ResourcesComponent,
    ContactComponent,
    TermsComponent,
    PrivacyComponent,
    AboutComponent,
    MountedSwatchesComponent,
    LoginComponent,
    ShoppingCartComponent,
    SlugifyPipe,
    ProfileComponent,
    UserOrdersComponent,
    //RegistrationComponent,
    BillingComponent,
    ShippingComponent,
    ConfirmationComponent,
    ProductComponent,
    StripePaymentComponent,
    StripeChargeComponent,
    DashboardComponent,
    OrdersComponent,
    OrderDetailComponent,
    UsersComponent,
    UserDetailComponent,
    ResetPasswordComponent,
    CheckresetpasswordComponent,
    AdminProductsComponent,
    ProductDetailComponent,
    AdminCategoriesComponent,
    AdminCategoryDetailComponent,
    LoadingComponent,
    SpecialCharacterDirective,
    CelebrationComponent,
    SubcategoriesComponent,
    DetailsComponent,
    SchoolComponent,
    StudentResourcesComponent,
    MediaComponent,
    ReturnsComponent,
    AdminMediaComponent,
    AdminMediaDetailComponent,
    SafePipe
  ],
  imports: [
    CommonModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    //DataTablesModule.forRoot(),
    FormsModule,
    HttpClientModule,
    MarkdownModule.forRoot(),
    MatButtonModule,
    MatTabsModule,
    ModalModule.forRoot(),
    NgxSpinnerModule,
    ReactiveFormsModule,
    TableModule,
    ToastrModule.forRoot({ positionClass: 'toast-bottom-right' }),
    DataViewModule,
    PaginatorModule,
    InputTextModule,
    ButtonModule,
    DropdownModule,
    JwtModule.forRoot({
        config: {
            tokenGetter: tokenGetter,
            allowedDomains: ["localhost:4201"],
            disallowedRoutes: []
        }
    }),
    ComplimentaryMaterialsComponent,
    CustomizedKitsComponent,
    HybridSwatchKitsComponent,
    SwatchKitAddonsComponent,
    KitModificationsComponent
],
  providers: [
    AuthGuard,
    BsModalRef,
    AdminService,
    CategoryService,
    ErrorService,
    LoggerService,
    NotificationService,
    SessionOrderService,
    ShoppingCartService,
    TokenService,
    UserService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
