<div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">Customized Kits</h5>
          <div class="row">
            <div class=" col-sm-12 col-md-8">
              <p>
                We now offer fully customizable kits tailored to meet the specific requirements of your course!
              </p>
              <ul>
                <li><b>All-in-One Resource:</b>&nbsp;A comprehensive solution for students to receive all necessary supplies in a single product.</li>
                <li><b>Flexible Usage:</b>&nbsp;Kits can be designed for both remote learning or in-class use, depending on your needs.</li>
                <li><b>Custom Orders:</b>&nbsp;Instructors provide details of the items they wish to include, and we will prepare a quote that includes economical ordering options for students.</li>
                <li><b>Purchase Options:</b>&nbsp;We offer wholesale pricing for departments and bookstores, as well as discounted pricing for direct-to-student orders.</li>
              </ul>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 pad-title">
              Below are just a few examples of how we are customizing our kits for various colleges and universities.
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 col-md-4">
              <img src="/assets/images/instructor_resources/columbus_college.jpg" width="300px" /><br/>
              <b>Columbus College of Art & Design</b><br/>
              <ul>
                <li>2024 Fashion Design Kit</li>
                <li><em>Fashion Designer's Textile Directory Textbook</em></li>
              </ul>
            </div>
            <div class="col-sm-12 col-md-4">
              <img src="/assets/images/instructor_resources/pasadena_city_college.jpg" width="300px" /><br/>
              <b>Pasadena City College</b><br/>
              <ul>
                <li>2024 Basic Textiles Swatch Kit</li>
                <li>Basic Felting Exercise</li>
                <li>Lab Kit</li>
              </ul>
            </div>
            <div class="col-sm-12 col-md-4">
              <img src="/assets/images/instructor_resources/central_michigan_university.jpg" width="300px" /><br/>
              <b>Central Michigan University</b><br/>
              <ul>
                <li>Lab Kit with glass beaker, test tubes, stir rod, tweezers, goggles, and more</li>
                <li>Can be bought separately or bundled with the Interior Design Swatch Kit</li>
              </ul>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12 col-md-4">
              <img src="/assets/images/instructor_resources/arizona_state_university.jpg" width="300px" /><br/>
              <b>Arizona State University</b><br/>
              <ul>
                <li>2024 Basic Textiles Swatch Kit</li>
                <li>Yarn Samples Packet</li>
                <li>Fabric Quiz Packet</li>
                <li>Activities Packet</li>
                <li>Wool Felting Packet</li>
                <li>Silk Cocoon</li>
                <li>Wool Fiber</li>
                <li>Cotton Fiber</li>
              </ul>
            </div>
            <div class="col-sm-12 col-md-4">
              <img src="/assets/images/instructor_resources/oklahoma_state_university.jpg" width="300px" /><br/>
              <b>Oklahoma State University</b><br/>
              <ul>
                <li>2024 Basic Textiles Swatch Kit</li>
                <li>2023 Materials for Interior Design Swatch Kit</li>
                <li>Yarn Samples Packet</li>
                <li>Fiber Samples Packet</li>
                <li>Large Fabric Samples</li>
                <li>Custom Lab Fabric Template</li>
              </ul>
            </div>
            <div class="col-sm-12 col-md-4">
              <img src="/assets/images/instructor_resources/mississippi_state_university.jpg" width="300px" /><br/>
              <b>Mississippi State University</b><br/>
              <ul>
                <li>2024 Interior Design Swatch Kit</li>
                <li>Trim Kit</li>
                <li>Fiber Sample Packet</li>
                <li>Yarn Sample Packet</li>
                <li>Mechanical Pencil</li>
                <li>Eraser</li>
                <li>Tape</li>
                <li>Stitch Counter</li>
              </ul>
              
            </div>
          </div>
        </div>
      </div>
    </div>
</div>