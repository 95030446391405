import { Component } from '@angular/core';

@Component({
  selector: 'app-complimentary-materials',
  standalone: true,
  imports: [],
  templateUrl: './complimentary-materials.component.html',
  styleUrl: './complimentary-materials.component.scss'
})
export class ComplimentaryMaterialsComponent {

}
