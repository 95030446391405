import { Component } from '@angular/core';

@Component({
  selector: 'app-hybrid-swatch-kits',
  standalone: true,
  imports: [],
  templateUrl: './hybrid-swatch-kits.component.html',
  styleUrl: './hybrid-swatch-kits.component.scss'
})
export class HybridSwatchKitsComponent {

}
