<div class="container">
  <div class="row">
    <div class="col-sm-12">

      <div class="card-content-area">

        <app-complimentary-materials></app-complimentary-materials>

        <app-customized-kits></app-customized-kits>

        <app-hybrid-swatch-kits></app-hybrid-swatch-kits>

        <app-swatch-kit-addons></app-swatch-kit-addons>

        <app-kit-modifications></app-kit-modifications>

        <p class="text-center instructions">
          Please contact us at <a href="mailto:susan@getyourswatchkit.com">Susan Elliott</a> to receive a
          wholesale price and more information on any of the above options.<br />
        </p>
      </div> <!-- end card content area -->
    </div>
  </div>
</div>
