<div class="container">
  <div class="row justify-content-center">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">Account Profile</h5>
          <form formGroup="profileForm" (ngSubmit)="submit()">
            <div class="form-group">
              <label>School Name</label>
              <input type="text" class="form-control" placeholder="Enter school name" formControlName="companyName" />
            </div>
            <div class="form-group">
              <label>First Name</label>
              <input type="text" class="form-control" placeholder="Enter first name" formControlName="firstName" />
            </div>
            <div class="form-group">
              <label>Last Name</label>
              <input type="text" class="form-control" placeholder="Enter last name" formControlName="lastName" />
            </div>
            <div class="form-group">
              <label>Email Address</label>
              <input type="text" class="form-control" placeholder="Enter last name" formControlName="emailAddress" />
            </div>

            <!-- <b>Billing Address</b>
            <div formGroupName="billTo">
              <div class="form-group">
                <label>Address Line #1</label>
                <input type="text" class="form-control" placeholder="Enter billing address line #1" formControlName="address1" />
              </div>
              <div class="form-group">
                <label>Address Line #2</label>
                <input type="text" class="form-control" placeholder="Enter billing address line #2" formControlName="address2" />
              </div>
              <div class="form-group">
                <label>City</label>
                <input type="text" class="form-control" placeholder="Enter billing city" formControlName="city" />
              </div>
              <div class="form-group">
                <label>State</label>
                <input type="text" class="form-control" placeholder="Enter billing state" formControlName="stateCode" />
              </div>
              <div class="form-group">
                <label>Postal Code</label>
                <input type="text" class="form-control" placeholder="Enter billing postal code" formControlName="postalCode" />
              </div>
              <div class="form-group">
                <label>Country</label>
                <input type="text" class="form-control" placeholder="Enter billing country" formControlName="countryCode" />
              </div>
              <div class="form-group">
                <label>Billing Phone Number</label>
                <input type="text" class="form-control" placeholder="Enter billing phone number" formControlName="phoneNumber" />
              </div>
            </div> -->
            <b>Shipping Address</b>
            <div formGroupName="shipTo">
              <div class="form-group">
                <label>Address Line #1</label>
                <input type="text" class="form-control" placeholder="Enter shipping address line #1" formControlName="address1" />
              </div>
              <div class="form-group">
                <label>Address Line #2</label>
                <input type="text" class="form-control" placeholder="Enter shipping address line #2" formControlName="address2" />
              </div>
              <div class="form-group">
                <label>City</label>
                <input type="text" class="form-control" placeholder="Enter shipping city" formControlName="city" />
              </div>
              <div class="form-group">
                <label>State</label>
                <input type="text" class="form-control" placeholder="Enter shipping state" formControlName="stateCode" />
              </div>
              <div class="form-group">
                <label>Postal Code</label>
                <input type="text" class="form-control" placeholder="Enter shipping postal code" formControlName="postalCode" />
              </div>
              <div class="form-group">
                <label>Country</label>
                <input type="text" class="form-control" placeholder="Enter shipping country" formControlName="countryCode" />
              </div>
              <div class="form-group">
                <label>Shipping Phone Number</label>
                <input type="text" class="form-control" placeholder="Enter shipping phone number" formControlName="phoneNumber" />
              </div>
            </div>
            <button class="btn btn-primary" type="submit"><i class="fa fa-check"></i>&nbsp;Save Profile</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
